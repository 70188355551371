<template>
  <div class="flex flex-col gap-10 mt-10">
    <section>
      <h2 class="mb-6 headline-xs">{{ $t('ads.packages') }}</h2>
      <p class="mt-4">
        Les mer om våre pakker og løsninger her eller ring oss på telefon
        <a :href="`tel:${$t('ads.phoneNumberFull')}`" class="text-blue">{{
          $t('ads.phoneNumberWithSpaces')
        }}</a>
        for en uforpliktende prat med en av våre medierådgivere.
      </p>
    </section>

    <section class="flex flex-col gap-2">
      <CommonCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-user"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.jobAds') }}
            </h2>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Rekruttér Norges beste ledere og spesialister mens de er på
                jobb. Dine annonser blir plassert i en
                <strong>trygg og seriøs</strong> kontekst.
              </p>
              <p>
                Ved å annonsere dine ledige stillinger på altinget.no vil du
                treffe både aktive og inaktive jobbsøkere. Du treffer målgruppen
                i en profesjonell kontekst og din annonse vil være synlig både
                på forsiden og på undersider. Selve annonsen utarbeides som en
                artikkel med mulighet for direkte link til søknadsskjema.
              </p>
              <p>Materiellspesifikasjon:</p>
              <ul>
                <li>Tekst som Word dokument</li>
                <li>Link til søknadsskjema</li>
                <li>Logo i png format, 500x500 px</li>
                <li>Søknadsfrist</li>
                <li>Link til YouTube video</li>
              </ul>
            </div>
          </div>
        </template>
      </CommonCollapsible>
      <CommonCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-desktop"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.bannerAds') }}
            </h2>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Altingets displayannonser er plassert på forside, underforside
                og på artikkelnivå, og vises på både desktop, tablet og mobil.
                Annonsene blir plassert i en trygg og seriøs kontekst.
              </p>
              <p>Materiellspesifikasjon:</p>
              <ul>
                <li>JPG, PNG og GIF</li>
                <li>Toppbanner 980x150 px</li>
                <li>Netboard 580x400 px</li>
                <li>Board 320x250 px</li>
                <li>Max vekt er 100 kb</li>
              </ul>
              <p>
                Alt materiell skal tydelig fremstå som reklame, og skal ikke
                etterlikne Altingets redaksjonelle uttrykk. All annonsering skal
                ha en tydelig avsender med annonsørlogo.
              </p>
              <p>
                Ved behov tilbyr vi bistand til produksjon av materiell via vår
                partner.
              </p>
            </div>
          </div>
        </template>
      </CommonCollapsible>
      <CommonCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-ad"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.nativeAds') }}
            </h2>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Ord er kraftfulle. Satt sammen på riktig måte kan de endre
                meninger og skape engasjement.
              </p>
              <p>
                Få artikkelen deres publisert på forsiden vår, og treff de
                riktige leserne i deres respektive nisje i en trygg og seriøs
                kontekst.
              </p>
              <p>
                Vi tilbyr en fremhevet plass med deres overskrift og logo - og
                minimum av støy når mottakeren leser ditt budskap.
              </p>
            </div>
          </div>
        </template>
      </CommonCollapsible>
    </section>
  </div>
</template>
